import React, { useState, useEffect } from 'react';
import ScorecardView from './ScorecardView';
import Header from '../Components/Header';
import axios from 'axios';
import {
  useParams,
} from "react-router-dom";

const RoundDisplayPage = () => {

	const [posts, setPosts] = useState([]);
	
	let  { roundId } = useParams();

    useEffect(() => {
       
	const url = '../retro/findonegigjson.php';
	
	var formData = new FormData();
	formData.append("myUID", localStorage.getItem("userID"));
	formData.append("pid", roundId);
	
	const config = { 'content-type': 'application/json' };
    const fetchPosts = async () => {
      await axios.post(url, formData, config)
	  .then(res => {const data =  res.data;
	  
	  //console.log(totalPosts);

      setPosts(data);});
    };

    fetchPosts();
		
		
		
		
    }, [roundId]);

    

    return(
	<>
	<Header />
        <ScorecardView 
		    posts={posts}
        />
		</>
    );

};

export default RoundDisplayPage;