import React, {useState} from "react";
import Header from '../Components/Header';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {Link} from "react-router-dom";

const PlayerCount = () => {
    const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  
  const dataOnePlayer = { playerCount: 1 };
  const dataTwoPlayer = { playerCount: 2 };
  const dataThreePlayer = { playerCount: 3 };
  const dataFourPlayer = { playerCount: 4 };

    return (
    <div>
      <button onClick={onOpenModal}>New Round</button>
      <Modal open={open} onClose={onCloseModal} center>
        <h2>How many players?</h2>
		<button><Link to="/new" state={dataOnePlayer}>1</Link></button>
		<button><Link to="/new" state={dataTwoPlayer}>2</Link></button>
		<button><Link to="/new" state={dataThreePlayer}>3</Link></button>
		<button><Link to="/new" state={dataFourPlayer}>4</Link></button>
      </Modal>
	  
	  
    </div>
  );
};

export default PlayerCount;