import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SignUp from './SignUp';
import Stats from './Components/Stats';
import About from './Components/About';
import ContactPage from './Components/contact-page'
import RoundDisplayPage from "./Components/RoundDisplayPage";
import ScorecardEnter from "./Components/ScorecardEnter";
import PlayerCount from "./Components/PlayerCount";

const router = createBrowserRouter([
  {
      path: '/',
      element: <SignUp />
  },
  {
      path: '/app',
      element: <App />
  },
  {
      path: '/statistics',
      element: <Stats />
  },
  {
      path: '/profile',
      element: <About />
  },
  {
      path: '/contact',
      element: <ContactPage />
  }
  ,
  {
      path: '/:roundId',
      element: <RoundDisplayPage />
  },
  {
      path: '/new',
      element: <ScorecardEnter />
  },
  {
      path: '/players',
      element: <PlayerCount />
  }
]);


//ReactDOM.render(<App />, document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <RouterProvider router={router} />
  </React.StrictMode>
);
registerServiceWorker();
