import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { auth } from "../config/Firebase";
import {onAuthStateChanged} from 'firebase/auth'
import Header from '../Components/Header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function About() {
  const navigate = useNavigate();
  const user = auth.currentUser;
  
  	const deleteSignedUser = async () => {
  
 
    const url = '../retro/delete_user.php';
	
	var formData = new FormData();
	formData.append("myUID", user.uid);
	
	const config = { 'content-type': 'application/json' };
	axios.post(url, formData, config)
	  .then(response => {})
            // User deleted.
			localStorage.removeItem("userID");
			localStorage.removeItem("loggedIn");
			user.delete();
			navigate(
          "../"
        );
}

const signOutUser = async () => {
 
    auth.signOut()
        .then(() => {
            // User deleted.
			localStorage.removeItem("userID");
			localStorage.removeItem("loggedIn");
			navigate(
          "../"
        );
        })
        .catch((error) => {
            // An error occurred
            // ...
        });
}

  useEffect(() => {
	  const loggedInUser = localStorage.getItem("loggedIn");
	if (loggedInUser) {
      const foundUser = loggedInUser;
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	
	
    // ...
  } else {
    // User is signed out
    // ...
  }
});
    }
	else{
    navigate(
          "../",
          {
            replace: true,
          }
        );
	}
	  
	
  }, [navigate]);
  
    const submit = () => {

    confirmAlert({
      title: 'Confirm',
      message: 'Are You Sure? All your data will be deleted.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteSignedUser()
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });
  }

    return (
	<div>
      <Header />
    
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src="images/profilepic.png" alt="" />
         </div>
         <div className="nine columns main-col">
			<div>
      <button onClick={submit}>Delete Account</button>
    </div>
	<div>
      <button onClick={signOutUser}>Log Out</button>
    </div>

            <p></p>
          
         </div>
      </div>

   </section>
   </div>
    );
}

export default About;