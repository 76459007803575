import React from 'react';
import '../styles/scorecard.css';

const ScorecardView = ({posts , }) => {
			let playerTwoNumbers = ["-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-"];
			let playerThreeNumbers = ["-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-"];
			let playerFourNumbers = ["-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-"];
			let frontNineP2 = "";
			let backNineP2 = "";
			let totalP2 = "";
			let frontNineP3 = "";
			let backNineP3 = "";
			let totalP3 = "";
			let frontNineP4 = "";
			let backNineP4 = "";
			let totalP4 = "";
			let holeNumbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18];
    return(
	<>
        {posts.map(function(data) {
			
			let parNumbers = data.parNumbersForMysql.substring(1, data.parNumbersForMysql.length - 1).split(",").map(Number);
			let playerOneNumbers = data.playerOneScoresForMysql.substring(1, data.playerOneScoresForMysql.length - 1).split(",").map(Number);
			
			if(data.playertwo !== "-"){
			playerTwoNumbers = data.playerTwoScoresForMysql.substring(1, data.playerTwoScoresForMysql.length - 1).split(",").map(Number);
			}
			else{
				playerTwoNumbers = ["-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-"];
			}
			
			if(data.playerthree !== "-"){
			playerThreeNumbers = data.playerThreeScoresForMysql.substring(1, data.playerThreeScoresForMysql.length - 1).split(",").map(Number);
			}
			else{
				playerThreeNumbers = ["-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-"];
			}
			
			if(data.playerfour !== "-"){
			playerFourNumbers = data.playerFourScoresForMysql.substring(1, data.playerFourScoresForMysql.length - 1).split(",").map(Number);
			}
			else{
				playerFourNumbers = ["-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-"];
			}
			
	let fullRoundDetails = holeNumbers.map((hole, i) => ({hole, par: parNumbers[i], firstplayer:  playerOneNumbers[i],secondplayer:  playerTwoNumbers[i],
		thirdplayer:  playerThreeNumbers[i],fourthplayer:  playerFourNumbers[i]
		}));
	
	let frontNinePar = fullRoundDetails.slice(0, 9).reduce((accumulator, current) => accumulator + current.par, 0);
	let backNinePar = fullRoundDetails.slice(9, 18).reduce((accumulator, current) => accumulator + current.par, 0);
	
	let frontNineP1 = fullRoundDetails.slice(0, 9).reduce((accumulator, current) => accumulator + current.firstplayer, 0);
	let backNineP1 = fullRoundDetails.slice(9, 18).reduce((accumulator, current) => accumulator + current.firstplayer, 0);
	
	if(data.playertwo !== "-"){
	frontNineP2 = fullRoundDetails.slice(0, 9).reduce((accumulator, current) => accumulator + current.secondplayer, 0);
	backNineP2 = fullRoundDetails.slice(9, 18).reduce((accumulator, current) => accumulator + current.secondplayer, 0);
	totalP2 = frontNineP2 + backNineP2;
	}
	else{
		frontNineP2 = "-";
		backNineP2 = "-";
		totalP2 = "-";
	}
	
	if(data.playerthree !== "-"){
	frontNineP3 = fullRoundDetails.slice(0, 9).reduce((accumulator, current) => accumulator + current.thirdplayer, 0);
	backNineP3 = fullRoundDetails.slice(9, 18).reduce((accumulator, current) => accumulator + current.thirdplayer, 0);
	totalP3 = frontNineP3 + backNineP3;
	}
	else{
		frontNineP3 = "-";
		backNineP3 = "-";
		totalP3 = "-";
	}
	
	if(data.playerfour !== "-"){
	frontNineP4 = fullRoundDetails.slice(0, 9).reduce((accumulator, current) => accumulator + current.fourthplayer, 0);
	backNineP4 = fullRoundDetails.slice(9, 18).reduce((accumulator, current) => accumulator + current.fourthplayer, 0);
	totalP4 = frontNineP4 + backNineP4;
	}
	else{
		frontNineP4 = "-";
		backNineP4 = "-";
		totalP4 = "-";
	}

	
	
      return (
        <form>
		<div className="scoreviewouter">
            <div className="scoreview">
                <div className="line">
				<span className="date">
                        {data.date}
                    </span>
                    <span className="course name">
                        {data.courseName}
                    </span>
                </div>
                
                <div className="line">
                    <span className="hole">Hole</span>
                    <span className="par">Par</span>
                    <span className="par">{data.playerone}</span>
					<span className="par">{data.playertwo}</span>
					<span className="par">{data.playerthree}</span>
					<span className="par">{data.playerfour}</span>
                </div>

                {fullRoundDetails.slice(0, 9).map((hole, par) => 
                    <div className="line" key={hole.hole} >
                        <span className="hole">{hole.hole}</span>
                        <span className="par">{hole.par}</span>
                        <span className="index">{hole.firstplayer}</span>
						<span className="index">{hole.secondplayer}</span>
						<span className="index">{hole.thirdplayer}</span>
						<span className="index">{hole.fourthplayer}</span>
                    </div>    
                )}
                
                <div className="line">
                    <span className="hole">Out</span>
                    <span className="par">{frontNinePar}</span>
                    <span className="par">{frontNineP1}</span>
					<span className="par">{frontNineP2}</span>
					<span className="par">{frontNineP3}</span>
					<span className="par">{frontNineP4}</span>
                </div>
            </div>
			
			
			
            <div className="scoreview">

                {fullRoundDetails.slice(9, 18).map((hole, par) => 
                    <div className="line" key={hole.hole} >
                        <span className="hole">{hole.hole}</span>
                        <span className="par">{hole.par}</span>
                        <span className="index">{hole.firstplayer}</span>
						<span className="index">{hole.secondplayer}</span>
						<span className="index">{hole.thirdplayer}</span>
						<span className="index">{hole.fourthplayer}</span>
                    </div>    
                )}

                <div className="line">
                    <span className="hole">In</span>
                    <span className="par">{backNinePar}</span>
                    <span className="par">{backNineP1}</span>
					<span className="par">{backNineP2}</span>
					<span className="par">{backNineP3}</span>
					<span className="par">{backNineP4}</span>
                </div>
                <div className="line">
                    <span className="hole">Total</span>
                    <span className="par">{frontNinePar+backNinePar}</span>
                    <span className="hole">{frontNineP1+backNineP1}</span>
					<span className="hole">{totalP2}</span>
					<span className="hole">{totalP3}</span>
					<span className="hole">{totalP4}</span>
                </div>
            </div>
			</div>
        </form>
      )
    })}
		</>
    );
};


export default ScorecardView;