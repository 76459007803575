// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = initializeApp({ 
  apiKey: "AIzaSyApZKppbxBUl3QS3Ou2fIkAeALTHVJLtKM",
  authDomain: "golfscoressite.firebaseapp.com",
  projectId: "golfscoressite",
  storageBucket: "golfscoressite.appspot.com",
  messagingSenderId: "140636753567",
  appId: "1:140636753567:web:fc8f797472340c659e1caa",
  measurementId: "G-S0H368CSCQ"
});

//window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// Initialize Firebase
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6LdbDAUqAAAAAP-NzYS3afJ8OpKl90smZwgVGd6w"),
  isTokenAutoRefreshEnabled: false // Set to true to allow auto-refresh.
});

export const auth =  getAuth(app);
export const googleProvider = new GoogleAuthProvider();
const analytics = getAnalytics(app);