/* global google */
import logo from './logoheader.png';
import './App.css';
import { useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode";
import {signInWithCredential, GoogleAuthProvider,getAuth, onAuthStateChanged} from 'firebase/auth'
import { auth } from "./config/Firebase";
import axios from 'axios';
import Footer from './Components/Footer';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';

function SignUp() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null)
  let navigate = useNavigate();
  const { state } = useLocation();
  const [myUID, setMyUID] = useState();

  const onOneTapSignedIn = response => {
    setIsSignedIn(true)
    const decodedToken = jwtDecode(response.credential)
    setUserInfo({...decodedToken})
	
	const credential = signInWithCredential(auth, GoogleAuthProvider.credential(response.credential))
        .then((result) => {
            const {user} = result;
			const {_tokenResponse} = result;
			const uid  = auth?.currentUser?.uid;
			
			
			setMyUID(uid);
			console.log(state);

			localStorage.setItem('loggedIn', 'true')
			localStorage.setItem('userID', uid)
			//console.log(response.credential)
			
			const url = '../retro/create_user.php';
			var formData = new FormData();
			formData.append("myUID", user.uid);
			formData.append("email", user.email);
			
			const config = { 'content-type': 'application/json' };
			axios.post(url, formData, config)
			.then(response => {
				
			navigate(
          "../app",
          {
            replace: true,
            state: { uid },
          }
        );
        })
        })
        .catch((error) => {
            console.error("firebase error", error);
        });
  }

  const initializeGSI = () => {
    google.accounts.id.initialize({
      client_id: '140636753567-9n93s372dm35qq4q7nfcl7hhvosqf8ep.apps.googleusercontent.com',
      cancel_on_tap_outside: false,
      callback: onOneTapSignedIn
    });
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed()) {
        console.log(notification.getNotDisplayedReason())
      } else if (notification.isSkippedMoment()) {
        console.log(notification.getSkippedReason())
      } else if(notification.isDismissedMoment()) {
        console.log(notification.getDismissedReason())
      }
    });
  }

  const signout = () => {
    // refresh the page
    window.location.reload();
  }

  useEffect(() => {
	const loggedInUser = localStorage.getItem("loggedIn");
	if (loggedInUser) {
      const foundUser = loggedInUser;
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	setIsSignedIn(true)
    setUserInfo(user);
    const uid = user.uid;
	const email = user.email;
	
	setMyUID(uid);
	console.log(state);
	
	navigate(
          "../app",
          {
            replace: true,
            state: { uid },
          }
        );
	
    // ...
  } else {
    // User is signed out
    // ...
  }
});
    }
	else{
    const el = document.createElement('script')
    el.setAttribute('src', 'https://accounts.google.com/gsi/client')
    el.onload = () => initializeGSI();
    document.querySelector('body').appendChild(el)
	}
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        
		<div className="playstorelogo"><a href="https://play.google.com/store/apps/details?id=com.randomhapps.golfscorecard" target="_blank">
		<img src="/images/playstore.png"  alt="logo" />
		</a></div>
      </header>
	  
	  
	 
	  
	  
	  <div className="Bottom">
          <div><Footer /></div>
      </div>
    </div>
	
  );
}

export default SignUp;
