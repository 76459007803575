import React, { useState, useEffect } from "react";
import axios from 'axios';
import Header from '../Components/Header';
import {onAuthStateChanged} from 'firebase/auth'
import { useNavigate } from "react-router-dom";
import { auth} from "../config/Firebase";

const Stats = () => {
  const [posts, setPosts] = useState([]); // State to hold posts
  const [postsPerPage] = useState(9); // Number of posts per page
  
  const navigate = useNavigate();

  useEffect(() => {
	  	  const loggedInUser = localStorage.getItem("loggedIn");
	if (loggedInUser) {
      const foundUser = loggedInUser;
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	
	
	
	const url = '../retro/findstatsjson.php';
	
	var formData = new FormData();
	formData.append("myUID", localStorage.getItem("userID"));
	formData.append("myLimit", "100");
	
	const config = { 'content-type': 'application/json' };
    const fetchPosts = async () => {
      const res = axios.post(url, formData, config)
	  .then(res => {const data =  res.data;

	  

      setPosts(data);});
    };

    fetchPosts();
	
	
    // ...
  } else {
    // User is signed out
    // ...
  }
});
    }
	else{
    navigate(
          "../18holes/",
          {
            replace: true,
          }
        );
	}
	  
  }, [navigate, postsPerPage]); // Fetch the next page of posts when the current page changes

  return (
    <div className="container">
      <PostList posts={posts} />
     
    </div>
  );
};

const PostList = ({ posts }) => {
  return (
   // <ul className="list-group mb-4">
     // {posts.map((post) => (
      //  <li key={post.pid} className="list-group-item">
      //    <h3>{post.albatross}</h3>
      //    <p>{post.birdies}</p>
      //  </li>
      //))}
    //</ul>
	
	<div>
      <Header />
	
	<section id="statistics">
	  
      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Statistics</span></h1>
			
         </div>
		 
         <div className="nine columns main-col">
		 
            <div className="row item">
			
               { posts.map(post =>  
			   
			   <tr  key={post.pid}>
                {(() => {
        if (post.pid.length > 1) {
          return (
		  
		  <div class="tg-wrap"><table class="tg">
				
				<tbody>
				<tr>
    <td>{"Hole In One"}</td>
    <td>{post.holesinone}</td>
  </tr>
  <tr>
    <td>{"Albatross"}</td>
    <td>{post.albatross}</td>
  </tr>
  
  
  <tr>
    <td>{"Eagle"}</td>
    <td>{post.eagles}</td>
  </tr>
  <tr>
    <td>{"Birdie"}</td>
    <td>{post.birdies}</td>
  </tr>
  
  <tr>
    <td>{"Par"}</td>
    <td>{post.pars}</td>
  </tr>
  
  <tr>
    <td>{"Bogey"}</td>
    <td>{post.bogeys}</td>
  </tr>
  <tr>
    <td>{"Double Bogey"}</td>
    <td>{post.doublebogies}</td>
  </tr>
  
  <tr>
    <td>{"Triple Bogey"}</td>
    <td>{post.triplebogies}</td>
  </tr>
  <tr>
    <td>{"Other"}</td>
    <td>{post.worsethantriplebogies}</td>
  </tr>
  
  
  
				  
					
				  
				</tbody>
				</table></div>
	 
       )
        }
		else if (post.pid.length < 1) {
          return (
            
		   <div class="tg-wrap"><table class="tg">

<tbody>
  <tr>
					
					<td class="tg-baqh">
					<p><a className = "venue-name"  href={post.linkweb}> {post.albatross}</a></p>
					</td>
					<td class="tg-baqh">
					<p>{post.birdies}</p>
					</td>
					
  </tr>
</tbody>
</table></div>
		  
		  
		  
          )
        } else {
          return (
            <div>catch all</div>
          )
        }
        })()}
            </tr>
		 
      )
	  
     }
	 
            </div>
         </div>
      </div>

   </section>
	</div>
	
	
  );
};



export default Stats;