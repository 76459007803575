import React, { useState, useEffect } from 'react';
import ScorecardEnterView from './ScorecardEnterView';
import testGolfClub from '../data/testGolfClub';
import Header from '../Components/Header';
import axios from 'axios';
import { useLocation } from "react-router-dom";

const ScorecardEnter = () => {
	const location = useLocation();
	const state = location.state;
	console.log(state);

    let [courseData, setCourseData] = useState(testGolfClub);
	let [courseName, setCourseName] = useState();
    let [playerOneName, setPlayerOneName] = useState();
	let [playerTwoName, setPlayerTwoName] = useState();
	let [playerThreeName, setPlayerThreeName] = useState();
	let [playerFourName, setPlayerFourName] = useState();
    let [handicap, setHandicap] = useState();
	let [playerCount, setPlayerCount] = useState();
    let [date, setDate] = useState((new Date().toISOString()))

    useEffect(() => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => {return {...hole, score: hole.score, points: calculatePoints(hole.score, hole.par, hole.index)}} )
        })
    }, [handicap]);

    const calculatePoints = (score, par, index) => {
        if(score){
            let hcap = parseInt(handicap);
            let shotsOnHole = 0;
            while(hcap > -18){
                if(parseInt(handicap) >= 0){
                    if(parseInt(index) <= hcap){
                        shotsOnHole++;
                    }
                }else{
                    if(shotsOnHole === 0 && parseInt(index) >= (19 + hcap)){
                        shotsOnHole--;
                    }
                }
                hcap -= 18;
            }
            
            let points = (parseInt(par) + 2) - (parseInt(score) - shotsOnHole);
    
            return points = points < 0 ? 0 : points;
        }

        return 0;
    }
	
	const updateCourseName = (courseNameUpdate) => {
        localStorage.setItem("courseName", courseNameUpdate);
    }
	
	const updatePlayerOneName = (playerOneNameUpdate) => {
        localStorage.setItem("playeronename", playerOneNameUpdate);
    }
	
	const updatePlayerTwoName = (playerTwoNameUpdate) => {
        localStorage.setItem("playertwoname", playerTwoNameUpdate);
    }
	
	const updatePlayerThreeName = (playerThreeNameUpdate) => {
        localStorage.setItem("playerthreename", playerThreeNameUpdate);
    }
	
	const updatePlayerFourName = (playerFourNameUpdate) => {
        localStorage.setItem("playerfourname", playerFourNameUpdate);
    }
	
	const updatePar = (holeIndex, par) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, par: par, points: calculatePoints(par, hole.par, hole.index)} : hole)
        });
	var parNumbers = JSON.parse(localStorage.getItem("parNumbers"));
    if(parNumbers == null) parNumbers = [];
    parNumbers[holeIndex] = par;
    localStorage.setItem("parNumbers", JSON.stringify(parNumbers));
    }

    const updateP1Score = (holeIndex, p1score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p1score: p1score, points: calculatePoints(p1score, hole.par, hole.index)} : hole)
        });
	var playerOne = JSON.parse(localStorage.getItem("playerOne"));
    if(playerOne == null) playerOne = [];
    playerOne[holeIndex] = p1score;
    localStorage.setItem("playerOne", JSON.stringify(playerOne));
    }
	
	const updateP2Score = (holeIndex, p2score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p2score: p2score, points: calculatePoints(p2score, hole.par, hole.index)} : hole)
        });
	var playerTwo = JSON.parse(localStorage.getItem("playerTwo"));
    if(playerTwo == null) playerTwo = [];
    playerTwo[holeIndex] = p2score;
    localStorage.setItem("playerTwo", JSON.stringify(playerTwo));
    }
	
	const updateP3Score = (holeIndex, p3score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p3score: p3score, points: calculatePoints(p3score, hole.par, hole.index)} : hole)
        });
	var playerThree = JSON.parse(localStorage.getItem("playerThree"));
    if(playerThree == null) playerThree = [];
    playerThree[holeIndex] = p3score;
    localStorage.setItem("playerThree", JSON.stringify(playerThree));
    }
	
	const updateP4Score = (holeIndex, p4score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p4score: p4score, points: calculatePoints(p4score, hole.par, hole.index)} : hole)
        });
	var playerFour = JSON.parse(localStorage.getItem("playerFour"));
    if(playerFour == null) playerFour = [];
    playerFour[holeIndex] = p4score;
    localStorage.setItem("playerFour", JSON.stringify(playerFour));
    }

    const calculateTotal = (holes, prop) => {
       let total = holes.reduce((total, hole) => {
            let value = parseInt(hole[prop] ? hole[prop] : 0);
            return total + value;
        }, 0);
       return total <= 0 ? "-" : total;
    }

    const onFocus = event => event.target.select();

    const onChangeHandicap = event => {
        if(!isNaN(event.target.value)){
            let hcap = parseInt(event.target.value);
            if(hcap < -17){
                hcap = -17;
                event.target.value = hcap;
            }else if (hcap > 99){
                hcap = 99;
                event.target.value = hcap;
            }
            setHandicap(hcap);
        }
        setHandicap(event.target.value);
    }

    const  handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }
	
	const addRound = async () => {
			const myUID = state.uid;
			const countPlayersOnStart = state.playerCount;
			const courseName = localStorage.getItem("courseName");
			const dateForMySQL = date.slice(0, 19).replace('T', ' ').split(' ')[0];
			const timestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
			const playerone = localStorage.getItem("playeronename");
			const playertwo = localStorage.getItem("playertwoname");
			const playerthree = localStorage.getItem("playerthreename");
			const playerfour = localStorage.getItem("playerfourname");
			
			const parNumbersForMysql = localStorage.getItem("parNumbers");
			const parNumbersForMysqlNoQuotes = parNumbersForMysql.replace (/"/g,'');
			
			const playerOneScoresForMysql = localStorage.getItem("playerOne");
			const playerOneScoresForMysqlNoQuotes = playerOneScoresForMysql.replace (/"/g,'');
			
			const playerTwoScoresForMysql = localStorage.getItem("playerTwo");
			const playerTwoScoresForMysqlNoQuotes = playerTwoScoresForMysql.replace (/"/g,'');
			
			const playerThreeScoresForMysql = localStorage.getItem("playerThree");
			const playerThreeScoresForMysqlNoQuotes = playerThreeScoresForMysql.replace (/"/g,'');
			
			const playerFourScoresForMysql = localStorage.getItem("playerFour");
			const playerFourScoresForMysqlNoQuotes = playerFourScoresForMysql.replace (/"/g,'');
			
			const url = '../retro/create_round.php';
			var formData = new FormData();
			formData.append("myUID", myUID);
			formData.append("countPlayersOnStart", countPlayersOnStart);
			formData.append("courseName", courseName);
			formData.append("date", dateForMySQL);
			formData.append("timestamp", timestamp);
			formData.append("playerone", playerone);
			formData.append("playertwo", playertwo);
			formData.append("playerthree", playerthree);
			formData.append("playerfour", playerfour);
			formData.append("parNumbersForMysql", parNumbersForMysqlNoQuotes);
			formData.append("playerOneScoresForMysql", playerOneScoresForMysqlNoQuotes);
			formData.append("playerTwoScoresForMysql", playerTwoScoresForMysqlNoQuotes);
			formData.append("playerThreeScoresForMysql", playerThreeScoresForMysqlNoQuotes);
			formData.append("playerFourScoresForMysql", playerFourScoresForMysqlNoQuotes);
			
			const config = { 'content-type': 'application/json' };
			axios.post(url, formData, config)
			.then(response => {
				
			
        })
	}

    return(
	<>
	<Header />
        <ScorecardEnterView 
            courseData={courseData}
            handicap={handicap}
            onChangeHandicap={onChangeHandicap}
			setPlayerCount={setPlayerCount}
			courseName={courseName}
            setCourseName={setCourseName}
            playerOneName={playerOneName}
            setPlayerOneName={setPlayerOneName}
			playerTwoName={playerTwoName}
            setPlayerTwoName={setPlayerTwoName}
			playerThreeName={playerThreeName}
            setPlayerThreeName={setPlayerThreeName}
			playerFourName={playerFourName}
            setPlayerFourName={setPlayerFourName}
            date={date}
            setDate={setDate}
			updateCourseName={updateCourseName}
			updatePlayerOneName={updatePlayerOneName}
			updatePlayerTwoName={updatePlayerTwoName}
			updatePlayerThreeName={updatePlayerThreeName}
			updatePlayerFourName={updatePlayerFourName}
			updatePar={updatePar}
            updateP1Score={updateP1Score}
			updateP2Score={updateP2Score}
			updateP3Score={updateP3Score}
			updateP4Score={updateP4Score}
            calculateTotal={calculateTotal}
            onFocus={onFocus}
            handleEnter={handleEnter}
        />
		
		</>
    );

};

export default ScorecardEnter;