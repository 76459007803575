import React, { Component } from 'react';

class Footer extends Component {
  render() {


    return (
      <footer>
	   

     <div className="footerlinks">
        
              <div ><a href="../privacy.html">Privacy</a></div>
           
        
		
     </div>
	 <div className="footerlinks">
        
		<div ><a href="../terms.html">Terms</a></div>
           
        
		
     </div>
  </footer>
    );
  }
}

export default Footer;
